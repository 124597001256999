import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class ToastService {
    private toastMessage = new BehaviorSubject<{ type: string, title: string, message: string } | null>(null);
    public readonly message$ = this.toastMessage.asObservable();
  
    show(type: string, title: string, message: string, duration = 3000) {
      this.toastMessage.next({ type, title, message });
      setTimeout(() => this.clear(), duration);
    }
  
    clear() {
      this.toastMessage.next(null);
    }
  }