import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';

const routes: Routes = [
  // Old URL
  { path: 'about', redirectTo: 'tentang-kami' },
  { path: 'profile-ustadz', redirectTo: 'profil-ustadz' },
  { path: 'contact', redirectTo: 'kontak' },
  { path: 'haji-jejak-imani', redirectTo: 'haji' },
  { path: 'catalog/haji-jejak-imani', redirectTo: 'haji' },
  { path: 'haji-khusus', redirectTo: 'haji/haji-plus' },
  { path: 'catalog/haji-khusus', redirectTo: 'haji/haji-plus' },
  { path: 'catalog/haji-furoda', redirectTo: 'haji/haji-furoda' },
  { path: 'umrah', redirectTo: 'umroh' },
  { path: 'catalog/umrah', redirectTo: 'umroh' },
  { path: 'detail-umrah', redirectTo: 'umroh' },
  { path: 'mau-tau-jadwal-umroh-2023-yuk-sesuaikan-jadwalnya-disini', redirectTo: 'umroh' },
  { path: 'umroh-hemat-umroh-private', redirectTo: 'umroh/umroh-lebih-hemat' },
  { path: 'umrah/umrah-lebih-hemat', redirectTo: 'umroh/umroh-lebih-hemat' },
  { path: 'package/umrah-lebih-hemat', redirectTo: 'umroh/umroh-lebih-hemat' },
  { path: 'package/umrah-lebih-nyaman', redirectTo: 'umroh/umroh-lebih-nyaman' },
  { path: 'package/umroh-lebih-nyaman', redirectTo: 'umroh/umroh-lebih-nyaman' },
  { path: 'umrah/umrah-lebih-nyaman', redirectTo: 'umroh/umroh-lebih-nyaman' },
  { path: 'umrah-jejak-nabi-bersama-ust-salim-a-fillah', redirectTo: 'umroh/umroh-bersama-ustadz-h-salim-a-fillah' },
  { path: 'umroh-hemat-umroh-jejak-nabi-bersama-ustad-salim', redirectTo: 'umroh/umroh-bersama-ustadz-h-salim-a-fillah' },
  { path: 'kategori/umrah-bersama-ustadz-salim-a-fillah', redirectTo: 'umroh/umroh-bersama-ustadz-h-salim-a-fillah' },
  { path: 'umrah/umrah-bersama-ustadz-h-salim-a-fillah', redirectTo: 'umroh/umroh-bersama-ustadz-h-salim-a-fillah' },
  { path: 'package/umrah-bersama-ustadz-h-salim-a-fillah', redirectTo: 'umroh/umroh-bersama-ustadz-h-salim-a-fillah' },
  { path: 'article', redirectTo: 'artikel' },
  { path: 'tabungan-umrah', redirectTo: 'umroh/tabungan-umroh' },
  { path: 'tabungan-umroh', redirectTo: 'umroh/tabungan-umroh' },
  { path: 'badal-haji-umrah', redirectTo: 'umroh/badal-haji-umroh' },
  { path: 'badal-haji-umroh', redirectTo: 'umroh/badal-haji-umroh' },
  { path: 'badal-umrah', redirectTo: 'badal/badal-umroh' },
  { path: 'badal-umroh', redirectTo: 'badal/badal-umroh' },
  { path: 'badal-haji', redirectTo: 'badal/badal-haji' },
  { path: 'badal-haji', redirectTo: 'badal/badal-haji' },
  { path: 'ruang-jamaah/gallery', redirectTo: 'ruang-jamaah/galeri' },
  { path: 'umroh/badal', redirectTo: 'badal' },
  { path: 'umroh/badal-haji', redirectTo: 'badal/badal-haji' },
  { path: 'umroh/badal-umroh', redirectTo: 'badal/badal-umroh' },
  { path: 'wisata-halal-product', redirectTo: 'pilihan-wisata-halal' },
  { path: 'wisata-halal', redirectTo: 'jelajah-dunya' },
  { path: 'pilihan-wisata-halal', redirectTo: 'pilihan-jelajah-dunya' },
  { path: 'request-calender', redirectTo: 'request-kalender' },
  // End Old Url

  {
    path: '',
    loadChildren: () => import('./jejak-imani/homepage/homepage.module').then(m => m.HomepageModule)
  },
  {
    path: 'manasik-online/:slug/:session',
    loadChildren: () => import('./jejak-imani/manasik-online/manasik-online.module').then(m => m.ManasikOnlineModule)
  },
  {
    path: 'survey/:slug',
    loadChildren: () => import('./jejak-imani/survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: 'konfirmasi-perlengkapan/:deliveryCode',
    loadChildren: () => import('./jejak-imani/equipment-confirmation/equipment-confirmation.module').then(m => m.EquipmentConfirmationModule)
  },
  {
    path: 'registrasi-jamaah/:bookingCode',
    loadChildren: () => import('./jejak-imani/booking-registration/booking-registration.module').then(m => m.BookingRegistrationModule)
  },
  {
    path: 'ruang-jamaah/campaign/:slug',
    loadChildren: () => import('./jejak-imani/jamaah-room/campaign-funding/campaign-funding.module').then(m => m.CampaignFundingModule)
  },
  {
    path: 'ruang-jamaah/campaign/:slug/process',
    loadChildren: () => import('./jejak-imani/jamaah-room/campaign-donation-process/campaign-donation-process.module').then(m => m.CampaignDonationProcessModule)
  },
  {
    path: 'ruang-jamaah/generate-invoice',
    loadChildren: () => import('./jejak-imani/jamaah-room/jamaah-generate-invoice/jamaah-generate-invoice.module').then(m => m.JamaahGenerateInvoiceModule)
  },
  {
    path: 'ruang-jamaah/galeri',
    loadChildren: () => import('./jejak-imani/jamaah-room/gallery/gallery.module').then(m => m.GalleryModule)
  },
  {
    path: 'ruang-jamaah/artikel',
    loadChildren: () => import('./jejak-imani/jamaah-room/article/article.module').then(m => m.ArticleModule)
  },
  {
    path: 'ruang-jamaah/kajian/:slug',
    loadChildren: () => import('./jejak-imani/jamaah-room/kajian-detail/kajian-detail.module').then(m => m.KajianDetailModule)
  },
  {
    path: 'ruang-jamaah/kajian',
    loadChildren: () => import('./jejak-imani/jamaah-room/kajian/kajian.module').then(m => m.KajianModule)
  },
  {
    path: 'ruang-jamaah',
    loadChildren: () => import('./jejak-imani/jamaah-room/jamaah-room.module').then(m => m.JamaahRoomModule)
  },
  {
    path: 'pilihan-jelajah-dunya',
    loadChildren: () => import('./jejak-imani/halal-tour-product/halal-tour-product.module').then(m => m.HalalTourProductModule)
  },
  {
    path: 'jelajah-dunya/detail/:slug',
    loadChildren: () => import('./jejak-imani/halal-tour-detail/halal-tour-detail.module').then(m => m.HalalTourDetailModule)
  },
  {
    path: 'jelajah-dunya/:category',
    loadChildren: () => import('./jejak-imani/halal-tour-category/halal-tour-category.module').then(m => m.HalalTourCategoryModule)
  },
  {
    path: 'jelajah-dunya',
    loadChildren: () => import('./jejak-imani/halal-tour-update/halal-tour.module').then(m => m.HalalTourModule)
  },
  {
    path: 'badal',
    loadChildren: () => import('./jejak-imani/badal-parent/badal-parent.module').then(m => m.BadalParentModule)
  },
  {
    path: 'badal/badal-haji',
    loadChildren: () => import('./jejak-imani/badal-category/badal-category.module').then(m => m.BadalCategoryModule)
  },
  {
    path: 'badal/badal-umroh',
    loadChildren: () => import('./jejak-imani/badal-category/badal-category.module').then(m => m.BadalCategoryModule)
  },
  // {
  //   path: 'umroh/:slug',
  //   loadChildren: () => import('./jejak-imani/package-category/package-category.module').then(m => m.PackageCategoryModule)
  // },
  // {
  //   path: 'umroh',
  //   loadChildren: () => import('./jejak-imani/parent-package/parent-package.module').then(m => m.ParentPackageModule)
  // },
  // {
  //   path: 'umroh/:category/:slug',
  //   loadChildren: () => import('./jejak-imani/package-detail/package-detail.module').then(m => m.PackageDetailModule)
  // },
  {
    path: 'tentang-kami',
    loadChildren: () => import('./jejak-imani/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'kontak',
    loadChildren: () => import('./jejak-imani/contact/contact.module').then(m => m.ContactModule)
  },
  // {
  //   path: 'haji',
  //   loadChildren: () => import('./jejak-imani/haji/haji.module').then(m => m.HajiModule)
  // },
  // {
  //   path: 'haji/haji-furoda',
  //   loadChildren: () => import('./jejak-imani/haji-furoda/haji-furoda.module').then(m => m.HajiFurodaModule)
  // },
  // {
  //   path: 'haji/haji-plus',
  //   loadChildren: () => import('./jejak-imani/haji-khusus/haji-khusus.module').then(m => m.HajiKhususModule)
  // },
  {
    path: 'profil-ustadz/:slug',
    loadChildren: () => import('./jejak-imani/profile-ustadz-update/profile-ustadz-detail/profile-ustadz-detail.module').then(m => m.ProfileUstadzDetailModule)
  },
  {
    path: 'profil-ustadz',
    loadChildren: () => import('./jejak-imani/profile-ustadz/profile-ustadz.module').then(m => m.ProfileUstadzModule)
  },
  {
    path: 'profil-ustadz-update',
    loadChildren: () => import('./jejak-imani/profile-ustadz-update/profile-ustadz.module').then(m => m.ProfileUstadzModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./jejak-imani/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'artikel',
    loadChildren: () => import('./jejak-imani/article/article.module').then(m => m.ArticleModule)
  },
  {
    path: 'artikel/:slug',
    loadChildren: () => import('./jejak-imani/article-detail/article-detail.module').then(m => m.ArticleDetailModule)
  },
  {
    path: 'editor/login',
    loadChildren: () => import('./editor/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'kebijakan-privasi',
    loadChildren: () => import('./jejak-imani/term-condition/term.module').then(m => m.TermModule)
  },
  {
    path: 'jios',
    loadChildren: () => import('./jejak-imani/cart/cartmain/cartmain.module').then(m => m.CartmainModule)
  },
  {
    path: 'success-checkout/:transactionId',
    loadChildren: () => import('./jejak-imani/cart/success-checkout/success-checkout.module').then(m => m.SuccessCheckoutModule)
  },
  {
    path: 'event-confirmation/:slug',
    loadChildren: () => import('./jejak-imani/event-confirmation/event-confirmation.module').then(m => m.EventConfirmationModule)
  },
  {
    path: 'event-registration/:slug',
    loadChildren: () => import('./jejak-imani/event-open-registration/event-open-registration.module').then(m => m.EventOpenRegistrationModule)
  },
  {
    path: 'departure-confirmation/:slug',
    loadChildren: () => import('./jejak-imani/departure-confirmation/departure-confirmation.module').then(m => m.DepartureConfirmationModule)
  },
  {
    path: 'document-sign/:slug',
    loadChildren: () => import('./jejak-imani/document-sign/document-sign.module').then(m => m.DocumentSignModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./jejak-imani/event/event.module').then(m => m.EventModule)
  },
  {
    path: 'events/:slug',
    loadChildren: () => import('./jejak-imani/event-detail/event-detail.module').then(m => m.EventDetailModule)
  },
  {
    path: 'success-checkout-event/:transactionId',
    loadChildren: () => import('./jejak-imani/cart/success-checkout-event/success-checkout-event.module').then(m => m.SuccessCheckoutEventModule)
  },
  {
    path: 'success-checkout-booking/:transactionId',
    loadChildren: () => import('./jejak-imani/cart/success-checkout-booking/success-checkout-booking.module').then(m => m.SuccessCheckoutBookingModule)
  },
  {
    path: 'umroh',
    loadChildren: () => import('./jejak-imani/umroh-update/umroh.module').then(m => m.UmrohModule)
  },
  {
    path: 'umroh/tabungan-umroh',
    loadChildren: () => import('./jejak-imani/tabungan-umroh/tabungan-umroh.module').then(m => m.TabunganUmrohModule)
  },
  {
    path: 'umroh/:slug',
    loadChildren: () => import('./jejak-imani/page-umroh/page-umroh.module').then(m => m.PageUmrohModule)
  },
  {
    path: 'umroh/:slug/:product',
    loadChildren: () => import('./jejak-imani/detail-product-umroh/detail-product-umroh.module').then(m => m.DetailProductUmrohModule)
  },
  // {
  //   path: 'new-umroh',
  //   loadChildren: () => import('./jejak-imani/page-umroh/page-umroh.module').then(m => m.PageUmrohModule)
  // },
  // {
  //   path: 'new-haji',
  //   loadChildren: () => import('./jejak-imani/page-haji/page-haji.module').then(m => m.PageHajiModule)
  // },
  // {
  //   path: 'umroh-hemat',
  //   loadChildren: () => import('./jejak-imani/page-umroh/page-umroh.module').then(m => m.PageUmrohModule)
  // },
  {
    path: 'request-kalender',
    loadChildren: () => import('./jejak-imani/registration-calender/registration-calender.module').then(m => m.RegistrationCalenderModule)
  },
  {
    path: 'haji',
    loadChildren: () => import('./jejak-imani/haji-update/haji.module').then(m => m.HajiModule)
  },
  {
    path: 'haji/:slug',
    loadChildren: () => import('./jejak-imani/page-haji/page-haji.module').then(m => m.PageHajiModule)
  },
  {
    path: '**',
    loadChildren: () => import('./jejak-imani/not-found/not-found.module').then(m => m.NotFoundModule)
  }

];


@NgModule({
  imports: [QuicklinkModule,
    RouterModule.forRoot(routes, {
      // preloadingStrategy: QuicklinkStrategy
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
