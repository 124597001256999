import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class LoaderService {
    private loadingMessage = new BehaviorSubject<string | null>(null);
    public readonly message$ = this.loadingMessage.asObservable();
  
    show(message: string) {
        this.loadingMessage.next(message);
    }
  
    hide() {
        this.loadingMessage.next(null);
    }
}