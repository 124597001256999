import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { CategoriessectiontwoComponent } from './homeone/categoriessectiontwo/categoriessectiontwo.component';
import { FooteroneComponent } from './common/footer/footerone/footerone.component';
import { FootertwoComponent } from './common/footer/footertwo/footertwo.component';
import { HeaderoneComponent } from './common/header/headerone/headerone.component';
import { MobilemenuComponent } from './common/header/mobilemenu/mobilemenu.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxLiteYoutubeModule } from 'ngx-lite-video'
import { MatFormFieldModule } from '@angular/material/form-field';
import { JejakimaniSafeHtmlPipe } from '../helpers/jejakimani-safe-html';
import { JejakImaniTitlePipe } from '../helpers/jejakimani-title.pipe';
import { JejakImaniStripHtmlPipe } from '../helpers/jejakimani-strip-html.pipe';
import { PhoneNumberPipe } from '../helpers/phone-number.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { CounterInputComponent } from './common/counter-input/counter-input.component';
import { UcwordsPipe } from '../helpers/ucwords';

// New Componen
import { EditorContentComponent } from './common/editor-content/editor-content.component';
import { HeroComponent } from './common/header/hero/hero.component';
import { FeatureComponent } from './common/feature/feature.component';
import { TestimonialWithVideoComponent } from './common/testimonial-with-video/testimonial-with-video.component';
import { FacilitiesComponent } from './common/facilities/facilities.component';
import { ContactFormComponent } from './common/contact-form/contact-form.component';
import { LocationWithMapComponent } from './common/location-with-map/location-with-map.component';
import { FaqComponent } from './common/faq/faq.component';
import { ContactSalesComponent } from './common/contact-sales/contact-sales.component';
import { ProductSliderComponent } from './common/product-slider/product-slider.component';
import { ProductOneComponent } from './common/product/product-one/product-one.component';
import { ProductTwoComponent } from './common/product/product-two/product-two.component';
import { ProductOtherComponent } from './common/product/product-other/product-other.component';
import { FooterParagraphComponent } from './common/footer-paragraph/footer-paragraph.component';
import { EditorSeoComponent } from './common/editor-seo/editor-seo.component';
import { EditorButtonComponent } from './common/editor-button/editor-button.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { EditorProductComponent } from './common/editor-product/editor-product.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SliderComponent } from './common/header/hero-backup/hero.component';
import { NewHeroComponent } from './common/new-hero/new-hero.component';
import { UmrohCardComponent } from './common/umroh-card/umroh-card.component';
import { NewContactFormComponent } from './common/new-contact-form/new-contact-form.component';
import { NewPreFooterComponent } from './common/new-pre-footer/new-pre-footer.component';
import { SeoParagraphComponent } from './common/seo-paragraph/seo-paragraph.component';
import { GlobalLoaderComponent } from './common/global-loader/global-loader.component';
import { CustomCarouselComponent } from './common/custom-carousel/custom-carousel.component';
import { AlphabetInputComponent } from './common/alphabet-input/alphabet-input.component';
import { InputPhoneComponent } from './common/input-phone/input-phone.component';
import { FormErrorMessageComponent } from './common/form-error-message/form-error-message.component';
import { FullHeroComponent } from './common/full-hero/full-hero.component';
import { ProductCardHajiComponent } from './common/product-card-haji/product-card-haji.component';
import { DetailProductUmrohComponent } from './detail-product-umroh/detail-product-umroh.component';
import { CardKeberangkatanComponent } from './common/card-keberangkatan/card-keberangkatan.component';
import { SEOUstadzSalimComponent } from './contents-seo/umroh-ustadz-salim';
import { CustomDatepickerComponent } from './common/custom-datepicker/custom-datepicker.component';
import { CarouselImageComponent } from './common/carousel-image/carousel-image.component';
import { HorizontalScrollContainerComponent } from './common/horizontal-scroll-container/horizontal-scroll-container.component';
import { ProductSliderNewComponent } from './common/product-slider-new/product-slider-new.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    HttpClientModule,
    SwiperModule,
    EditorModule,
    NgOptimizedImage,
    NgxLiteYoutubeModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatDatepickerModule
  ],
  declarations: [
    CategoriessectiontwoComponent,
    FooteroneComponent,
    FootertwoComponent,
    HeaderoneComponent,
    MobilemenuComponent,
    JejakImaniTitlePipe,
    JejakImaniStripHtmlPipe,
    JejakimaniSafeHtmlPipe,
    PhoneNumberPipe,
    CounterInputComponent,
    UcwordsPipe,

    // New
    EditorContentComponent,
    HeroComponent,
    FeatureComponent,
    TestimonialWithVideoComponent,
    FacilitiesComponent,
    ContactFormComponent,
    LocationWithMapComponent,
    FaqComponent,
    ContactSalesComponent,
    FooterParagraphComponent,
    // Product
    ProductSliderComponent,
    ProductOneComponent,
    ProductTwoComponent,
    ProductOtherComponent,
    // Editor
    EditorButtonComponent,
    EditorContentComponent,
    EditorSeoComponent,
    EditorProductComponent,
    SliderComponent,
    // NEW
    NewHeroComponent,
    UmrohCardComponent,
    NewContactFormComponent,
    NewPreFooterComponent,
    SeoParagraphComponent,
    CustomCarouselComponent,
    AlphabetInputComponent,
    InputPhoneComponent,
    FormErrorMessageComponent,
    FullHeroComponent,
    ProductCardHajiComponent,
    CardKeberangkatanComponent,
    SEOUstadzSalimComponent,
    CustomDatepickerComponent,
    CarouselImageComponent,
    HorizontalScrollContainerComponent,
    ProductSliderNewComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    HttpClientModule,
    SwiperModule,
    EditorModule,
    NgOptimizedImage,
    NgxLiteYoutubeModule,
    NgxPaginationModule,
    CategoriessectiontwoComponent,
    FooteroneComponent,
    FootertwoComponent,
    HeaderoneComponent,
    MobilemenuComponent,
    JejakImaniTitlePipe,
    JejakImaniStripHtmlPipe,
    JejakimaniSafeHtmlPipe,
    PhoneNumberPipe,
    CounterInputComponent,
    UcwordsPipe,

    // New
    EditorContentComponent,
    HeroComponent,
    FeatureComponent,
    TestimonialWithVideoComponent,
    FacilitiesComponent,
    ContactFormComponent,
    LocationWithMapComponent,
    FaqComponent,
    ContactSalesComponent,
    FooterParagraphComponent,
    // Product
    ProductSliderComponent,
    ProductOneComponent,
    ProductTwoComponent,
    ProductOtherComponent,
    // Editor
    EditorButtonComponent,
    EditorContentComponent,
    EditorSeoComponent,
    EditorProductComponent,
    SliderComponent,
    // Kitsuma New
    NewHeroComponent,
    UmrohCardComponent,
    NewContactFormComponent,
    NewPreFooterComponent,
    SeoParagraphComponent,
    CustomCarouselComponent,
    AlphabetInputComponent,
    InputPhoneComponent,
    FormErrorMessageComponent,
    FullHeroComponent,
    ProductCardHajiComponent,
    CardKeberangkatanComponent,
    CustomDatepickerComponent,
    CarouselImageComponent,
    HorizontalScrollContainerComponent,
    ProductSliderNewComponent
  ]
})
export class JejakImaniModule { }
