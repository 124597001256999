import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JejakImaniModule } from './jejak-imani/jejakimani.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { HttpXsrfInterceptor } from './helpers/http.interceptor';
import {TransferHttpResponseInterceptorService} from './services/transfer-http-response-interceptor.service';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id'; 
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { GlobalToastComponent } from './jejak-imani/common/global-toast/global-toast.component';
import { GlobalLoaderComponent } from './jejak-imani/common/global-loader/global-loader.component';
registerLocaleData(localeId, 'id'); 

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'website-jejak-imani' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    JejakImaniModule,
  ],
  declarations: [
    AppComponent,
    GlobalToastComponent,
    GlobalLoaderComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TransferHttpResponseInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: "id-ID" },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
